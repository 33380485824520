import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One of the highlights of SuperBot 2018 was an opportunity to learn more from Michael Francisco, Amazon’s Head of Alexa Skills Kit Partners. Michael shares tips and tricks for building better Skills, popular use cases, and strategies for user acquisition and monetization.`}</p>
    <h2><strong parentName="h2">{`Tell us about yourself and how you got in this space.`}</strong></h2>
    <p>{`I’ve been with the Alexa team for a little over two years now, which makes me an OG in this space. I had the opportunity to be part of the Alpha program when the initial Alexa devices came into your house where it did nothing more than tell you the weather and maybe a news result once in a while and sometimes get the music right. I’ve been involved since the very beginning and it’s been exciting to watch it grow, not just voice but conversational UI as a whole. It’s changed the way people interact with technology and brands and we believe it’s the future.`}</p>
    <h2><strong parentName="h2">{`Amazon has integrated Alexa into many third-party hardware companies and we heard Alexa was working with hotels to deploy devices in rooms. How do you decide who to partner with?`}</strong></h2>
    <p>{`We think about Alexa integration in two-parts: the Alexa Skills Kit and Alexa as a platform. We want to make the Alexa Skills Kit easier for brands to bring their experience to consumers that helps with their overall strategy. We want to make Alexa as a platform available to everyone so we made it open source. We encourage innovation and support folks we see who are gaining traction. We’ll continue investing in people and these devices, which is evident in heavy investment in multi-modal with devices like Echo Show and Echo Spot. Eventually, more companies will introduce larger screens connected to Alexa which was clear at CES where there were a number of vendors who revealed a primary screen with Alexa. We hone in on consumer environments, but we find that hotels are an enterprise environment that’s retrofitted for the consumer experience that people find delightful. An example of this is our work with Volara, they’ve created a CRM solution to deliver unique hospitality experiences for their guests and we support that with the Alexa for Business.`}</p>
    <h2><span style={{
        "fontWeight": "400"
      }}><strong parentName="h2">{`With over 31,000 skills on the platform, are there use cases that you see more popular than others?`}</strong>{` `}</span></h2>
    <p>{`I have very little free time so I end up using Alexa for very transactional, pragmatic things like checking my bank account. I would love to be able to check into flights using Alexa. I find those use cases very interesting. Lifestyle – meditation, workouts, and sleep sounds. People like that. It’s been interesting to watch this because you’ll see these initial experiences, which will expand as people get more comfortable with the platform and we make those experiences more simple. We need to start displacing mobile as the device of choice for almost all of our activities in order for Alexa, or any voice platform, to be fully adopted. When I talk to people about building Skills, I start with the question, “Is it easier to build what you want on voice or mobile devices?” There are things that Alexa is capable of that I don’t do on Alexa because it’s much easier to get my phone out and order something. Multi-modal screens and great conversational design will help with this transition. Understanding how customers want to interact with the device, simplifying the experience, and driving that forward will be how we get customers to adopt broader Skills.`}</p>
    <h2><strong parentName="h2">{`People previously used the term “mobile first”. Do you see a “voice first” world?`}</strong></h2>
    <p>{`We’re seeing some voice-first experiences happening on the platform, but what’s missing is monetization. Monetization is going to be the next big hook that we’re going to figure out how to do right, which will drive a ton of “voice first” Skills. Voice is kind of an adjunct because there’s no ROI yet. There’s a thoughtful placement of voice within your consumer engagement strategy. When we talk to big brands, they’re managing a really cool project in their innovation department. Then, they bring it to their marketing team and marketing says, “Well, what do we do with this?” Brands need to think about Alexa as part of their all-up strategy. How does voice fit into your social, mobile, and online strategy to tie in consumer experience.`}</p>
    <h2><strong parentName="h2">{`Are there any Skills that surprised you because they built something you hadn’t thought of for voice?`}</strong></h2>
    <p>{`The Destiny Skill by Activision is a great example of doing something different with voice. It’s taking Alexa and putting her in an appropriate place in the game where she’s a companion. For most of the things regarding Alexa, is the primary experience where you’re engaging with her directly, or is she helping you along the experience? With the Destiny Skill, that team really thought about how to make a gaming experience better using voice.`}</p>
    <h2><strong parentName="h2">{`Alexa has been available for a couple years, what are some of the learnings you’ve found about users interactions with the devices, or how developers are building for the device?`}</strong></h2>
    <p>{`We initially thought people wanted to have conversations with the device, but we’ve actually seen that people don’t want to have a long-form conversation and don’t see her as their “robot friend.” People want the experience to be fast and want things to happen quickly with just a few utterances. We’re seeing customer fatigue with Skills where they have to guess and seeing a lot of migration from Skill to Skill to Skill to find one where they don’t have to learn. With the rise of multi-modal experiences, we see customers leveraging the screen, but it’s still secondary input.`}</p>
    <h2><strong parentName="h2">{`Do you see or recommend people putting personality in their Skills?`}</strong></h2>
    <p>{`I like to compare two specific Skills as an example for this question – the Patron Skill and the Johnny Walker Skill, both intent on selling you more booze. Both have similar functionality in terms of offering history about the liquor and tell you about the brand. Johnny Walker has a tasting experience and Patron has a recipe component. They both still use Alexa’s voice, but the way they conduct conversations really sets the two brands apart. Patron is much more formal and in line with a traditional brand, where as Johnny Walker is more irreverent and tosses jokes at you. It really comes through in the writing and the Skill design. We’ve added tools in the Alexa Skills Kit where you can add breath and laughter to build a dynamic experience, but the writing is what will ensure that your brand principles shine through in Alexa’s voice.`}</p>
    <h2><strong parentName="h2">{`Are there monetization models you’re looking at?`}</strong></h2>
    <p>{`The trick right now is figuring out how customers want to engage around monetization so that we don’t roll something out that’s half-baked and doesn’t have the customer in mind. We know how important it is for developers to invest in something that’s going to bring your money back so we’re working hard to figure that out.`}</p>
    <h2><strong parentName="h2">{`On the developer side, what kinds of things are you doing to attract developers to build on your platform?`}</strong></h2>
    <p>{`We have all the traditional marketing things, but what’s really important is making it easier for developers to build and understand why they should be building for this platform. For us, it’s about the value proposition and what kind of value they can bring to their customer. I’ve been saying for a long time that not everyone needs a Skill so you need to be able to articulate to your business why you need this.`}</p>
    <h2><strong parentName="h2">{`Are there any best practices you can share for developers?`}</strong></h2>
    <p>{`You want to make sure that whatever you’re building is easier on voice than anywhere else. Customers are only going to come back to Skills that can do that. Think about how you advertise and market your Skills to users and how it ties into the rest of your channel mix. The last piece of advice I’d give is to test, test, test. Testing from a functional standpoint is important, but user acceptance testing is very important because you get to see what users do with your Skill and if your Skill can deliver what you think. Think about your end-to-end strategy.`}</p>
    <h2>{`User Survey on Alexa Devices`}</h2>
    <p>{`We conducted a `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2017/11/21/the-impact-of-alexa-and-google-home-on-consumer-behavior/"
      }}>{`survey`}</a>{` of owners of Alexa devices to better understand how they use the devices, the impact the devices have had on user behavior, and their overall impressions of the device. For more info, check out our survey: `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2017/11/21/the-impact-of-alexa-and-google-home-on-consumer-behavior/"
      }}>{`The Impact of Alexa and Google Home on Consumer Behavior`}</a>{`.`}</p>
    <h2>{`Dashbot Alexa Skills Analytics`}</h2>
    <p><em parentName="p">{`Dashbot provides `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/features/alexa/"
        }}>{`analytics`}</a>{` for Alexa Skills to help developers and brands gain insights into user behaviors, increase engagement and retention, and build better user experiences in their Skills.`}</em></p>
    <p><em parentName="p">{`We have full transcripts featuring Intents and context, Intent specific reports including slot parameter values, in addition to behavior flows, interaction funnels, and goal funnels. We also provide comparison metrics, like our `}<a parentName="em" {...{
          "href": "https://reports.dashbot.io/ci/alexa"
        }}>{`Competitive Intelligence`}</a>{` product for Alexa Skills.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      